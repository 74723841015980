import { Image, ImageProps } from "@graphcommerce/image";
import {
  ProductListItemFragment,
  useProductLink,
} from "@graphcommerce/magento-product";
import { ProductReviewChip } from "@graphcommerce/magento-review";
import { Money } from "@graphcommerce/magento-store";
import {
  extendableComponent,
  responsiveVal,
  useNumberFormat,
} from "@graphcommerce/next-ui";
import { Trans } from "@lingui/react";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
  styled,
  useEventCallback,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useSpecialProductPrice } from "../../../../hooks/useSpecialProductPrice";
import { ProductListPrice } from "./ProductListPrice";

const { classes, selectors } = extendableComponent("ProductListItem", [
  "root",
  "item",
  "title",
  "titleContainer",
  "subtitle",
  "price",
  "overlayItems",
  "topLeft",
  "topRight",
  "bottomLeft",
  "bottomRight",
  "imageContainer",
  "placeholder",
  "image",
  "discount",
  "discountPrice",
] as const);

export type OverlayAreaKeys =
  | "topLeft"
  | "bottomLeft"
  | "topRight"
  | "bottomRight";

export type OverlayAreas = Partial<Record<OverlayAreaKeys, React.ReactNode>>;

type StyleProps = {
  aspectRatio?: [number, number];
  imageOnly?: boolean;
};

type BaseProps = {
  subTitle?: React.ReactNode;
  children?: React.ReactNode;
  bundle?: boolean;
} & StyleProps &
  OverlayAreas &
  ProductListItemFragment &
  Pick<ImageProps, "loading" | "sizes" | "dontReportWronglySizedImages">;

export type ProductListItemProps = BaseProps & {
  sx?: SxProps<Theme>;
  titleComponent?: React.ElementType;
  onClick?: (
    // eslint-disable-next-line no-unused-vars
    event: React.MouseEvent<HTMLAnchorElement>,
    // eslint-disable-next-line no-unused-vars
    item: ProductListItemFragment,
  ) => void;
};

const StyledImage = styled(Image)({});

const Price = ({ product, discount }) => (
  <Stack
    direction={"row"}
    alignItems="center"
    justifyContent={"space-between"}
    flexWrap="wrap"
    gap="6px"
  >
    <Typography
      component="p"
      sx={{
        color: "text.primary",
        overflowWrap: "break-word",
        wordBreak: "break-all",
        maxWidth: "100%",
        fontWeight: "fontWeightBold",
      }}
    >
      <Money
        value={discount ? product?.promotion_scale_price : product?.scale_price}
      />{" "}
      {product?.price_label} / {product?.scale_label}
    </Typography>
    <ProductReviewChip
      rating={product.rating_summary}
      reviewSectionId="reviews"
      sx={{ fontSize: { xs: "10px", md: "12px" } }}
    />
  </Stack>
);

export function ProductCard(props: ProductListItemProps) {
  const {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    // eslint-disable-next-line no-unused-vars
    aspectRatio = [4, 3],
    titleComponent = "h2",
    sx = [],
    onClick,
    bundle = false,
    ...defaultProduct
  } = props;

  const defaultProductCheckMoneyLabel = defaultProduct as any;
  const { small_image, name } = defaultProduct;
  const { product, isLoading } = useSpecialProductPrice(defaultProduct);

  const { price_range, special_to_date } = product;

  const handleClick = useEventCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => onClick?.(e, props),
  );

  const productLink = useProductLink(props);
  const discount = Math.floor(
    price_range.minimum_price.discount?.percent_off ?? 0,
  );
  const date = special_to_date ? special_to_date : new Date();
  const moneyLabel =
    product?.main_sku &&
    defaultProductCheckMoneyLabel?.items?.length > 1 &&
    "À partir de";
  const specialDate: string = dayjs(date).format("DD/MM/YYYY");
  const formatter = useNumberFormat({
    style: "percent",
    maximumFractionDigits: 1,
  });

  return (
    <ButtonBase
      href={productLink}
      sx={[
        () => ({
          display: "block",
          position: "relative",
          height: "100%",
          minHeight: "100%",
          borderRadius: "12px",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={classes.root}
      onClick={onClick ? handleClick : undefined}
    >
      <Box
        sx={() => ({
          display: "grid",
          background: "white",
          border: "1px solid #dadada",
          borderRadius: "12px",
          overflow: "hidden",
          padding: responsiveVal(8, 12),
          minWidth: "150px",
          minHeight: "150px",
          "& > picture": {
            gridArea: `1 / 1 / 3 / 3`,
            margin: `calc(${responsiveVal(8, 12)} * -1)`,
          },
        })}
        className={classes.imageContainer}
      >
        {small_image ? (
          <StyledImage
            layout="fill"
            sizes={sizes}
            dontReportWronglySizedImages={dontReportWronglySizedImages}
            src={small_image.url ?? ""}
            alt="Product"
            className={classes.image}
            loading={loading}
            sx={{
              objectFit: "contain",
              aspectRatio: "4 / 3",
            }}
          />
        ) : (
          <Box
            sx={{
              gridArea: `1 / 1 / 3 / 3`,
              typography: "caption",
              display: "flex",
              textAlign: "center",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              color: "background.default",
              userSelect: "none",
            }}
            className={`${classes.placeholder} ${classes.image}`}
          >
            <Trans id="No Image" />
          </Box>
        )}

        {!imageOnly && (
          <>
            <Box
              sx={{
                gridArea: `1 / 1 / 2 / 2`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {topLeft}
            </Box>
            <Box
              sx={{
                justifySelf: "end",
                textAlign: "right",
                gridArea: `1 / 2 / 2 / 3`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {topRight}
            </Box>
            <Box
              sx={{
                alignSelf: "flex-end",
                gridArea: `2 / 1 / 3 / 2`,
                zIndex: 1,
              }}
              className={classes.bottomLeft}
            >
              {bottomLeft}
            </Box>
            <Box
              sx={{
                textAlign: "right",
                alignSelf: "flex-end",
                gridArea: `2 / 2 / 3 / 3`,
                zIndex: 1,
                justifySelf: "end",
              }}
              className={classes.bottomRight}
            >
              {bottomRight}
            </Box>
          </>
        )}
      </Box>

      {!imageOnly && (
        <>
          <Box
            className={classes.titleContainer}
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "baseline",
              marginTop: theme.spacings.xs,
              gap: 1,
            })}
          >
            {discount > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: { xs: "5px", md: "40px" },
                }}
                className="discountBanner"
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h4"
                    component="p"
                    sx={{ color: "primary.main" }}
                  >
                    PROMOTION
                  </Typography>
                  <Typography
                    variant="small"
                    component="span"
                    sx={{ color: "primary.main" }}
                  >
                    jusqu’au {specialDate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    lineHeight: "24px",
                    px: { xs: "5px", sm: "10px" },
                    backgroundColor: "primary.main",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    component="p"
                    sx={{
                      textAlign: "center",
                      color: "primary.light",
                      fontSize: { xs: "12px", sm: "16px", md: "18px" },
                    }}
                  >
                    {formatter.format(discount / -100)}
                  </Typography>
                </Box>
              </Box>
            )}
            <Typography
              component={titleComponent}
              variant="subtitle1"
              sx={{
                display: "inline",
                color: "text.primary",
                overflowWrap: "break-word",
                maxWidth: "100%",
                gridArea: "title",
                fontWeight: "fontWeightBold",
              }}
              className={classes.title}
            >
              {name}
            </Typography>

            {isLoading ? (
              <CircularProgress role="progressbar" title="loading" />
            ) : (
              <Box
                component="div"
                className="priceStyleBanner"
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  flexDirection: `${bundle ? "row" : "column-reverse"}`,
                  width: "100%",
                }}
              >
                {!!product?.scale_price && !!product?.scale_label && (
                  <Box>
                    {!discount ? (
                      <Box component="div" className="scalePriceBanner">
                        <Typography
                          component="p"
                          sx={{
                            marginBottom: "5px",
                          }}
                        >
                          {moneyLabel}
                        </Typography>
                        <Price product={product} discount={discount} />
                      </Box>
                    ) : (
                      <Box
                        component="div"
                        className="scalePriceBanner"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography
                          component="p"
                          sx={{
                            marginBottom: "5px",
                          }}
                        >
                          {moneyLabel}
                        </Typography>
                        <Typography
                          component="p"
                          className={classes.discountPrice}
                          sx={{
                            textDecoration: "line-through",
                            color: "text.disabled",
                            marginRight: "8px",
                          }}
                        >
                          <Money value={product?.scale_price} />{" "}
                          {product?.price_label} / {product?.scale_label}
                        </Typography>
                        <Price product={product} discount={discount} />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {children}
        </>
      )}
    </ButtonBase>
  );
}

ProductCard.selectors = { ...selectors, ...ProductListPrice.selectors };
