/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const CustomerTypeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CustomerType"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"account_type"}}]}}]}}]} as unknown as DocumentNode<CustomerTypeQuery, CustomerTypeQueryVariables>;
export type CustomerTypeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerTypeQuery = { customer?: { account_type?: Types.CustomerAccountType | null } | null };
