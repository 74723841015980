import { CustomerTypeDocument } from "@/graphql/CustomerType.gql";
import { ParticularProductPriceDocument } from "@/graphql/ParticularProductPrice.gql";
import { ProfessionalProductPriceDocument } from "@/graphql/ProfessionalProductPrice.gql";
import { useQuery } from "@graphcommerce/graphql";
import { useCustomerSession } from "@graphcommerce/magento-customer";
import { useEffect, useMemo, useState } from "react";

export const useSpecialProductPrice = (product: any): any => {
  const { loggedIn } = useCustomerSession();
  const [isLoading, setLoading] = useState(true);
  const { loading: customerLoading, data: customerData } = useQuery(
    CustomerTypeDocument,
    {
      ssr: false,
      skip: !loggedIn,
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
  );

  const customer = customerData?.customer;
  const isGuest = !customer?.account_type;
  const isParticular = customer?.account_type === "PARTICULAR";
  const isProfessional = customer?.account_type === "PROFESSIONAL";
  const sku = product?.main_sku || product?.sku;

  const { loading: professionalLoading, data: professionalData } = useQuery(
    ProfessionalProductPriceDocument,
    {
      variables: { sku },
      ssr: false,
      skip: isGuest || isParticular || !sku,
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
  );

  const { loading: particularLoading, data: particularData } = useQuery(
    ParticularProductPriceDocument,
    {
      variables: { sku },
      ssr: false,
      skip: isGuest || isProfessional || !sku,
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
  );

  const customPricedProduct = useMemo(() => {
    const particularProductPrice =
      particularData?.getInvisibleProduct?.items?.[0] || {};
    const professionalProductPrice =
      professionalData?.professionalProductPrice || {};
    let mainProduct = product;
    if (product?.__typename === "GroupedProduct") {
      mainProduct = {
        ...product,
        ...product?.items?.find((i) => i.product.sku === product.main_sku)
          ?.product,
      };
    }

    return {
      ...mainProduct,
      ...particularProductPrice,
      ...professionalProductPrice,
    };
  }, [product, particularData, professionalData, loggedIn, isLoading]);

  useEffect(() => {
    setLoading(customerLoading || particularLoading || professionalLoading);
  }, [customerLoading, professionalLoading, particularLoading]);

  return { isLoading, product: customPricedProduct };
};
