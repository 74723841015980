import { ProductListPriceFragment } from "@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice.gql";
import { Money } from "@graphcommerce/magento-store";
import { extendableComponent } from "@graphcommerce/next-ui";
import { Box, Typography, TypographyProps } from "@mui/material";

const { classes, selectors } = extendableComponent("ProductListPrice", [
  "root",
  "discountPrice",
] as const);

type ProductListPriceProps = ProductListPriceFragment &
  Pick<TypographyProps, "sx"> & {
    conditionnement: string;
    nb_cond_vte: string;
    main_sku: string;
    scale_price: string | number;
    scale_value: string | number;
    price_label: string;
  };

export function ProductListPrice(props: ProductListPriceProps) {
  const {
    regular_price,
    final_price,
    conditionnement,
    nb_cond_vte,
    scale_value,
    scale_price,
    price_label,
    sx,
  } = props;
  const displayUnit = conditionnement && nb_cond_vte;
  const moneyLabel = scale_value === null || scale_price === null ? "" : "Soit";

  return (
    <Typography
      component="div"
      variant="body1"
      className={classes.root}
      sx={sx}
    >
      {regular_price.value !== final_price.value && (
        <Box
          component="span"
          className={classes.discountPrice}
          sx={{
            textDecoration: "line-through",
            color: "text.disabled",
            marginRight: "8px",
          }}
        >
          {moneyLabel} <Money {...regular_price} /> {price_label}{" "}
          {displayUnit && (
            <>
              / {conditionnement} ({nb_cond_vte} unité)
            </>
          )}
        </Box>
      )}
      <Box sx={{ marginTop: "1px" }}>
        {moneyLabel} <Money {...final_price} /> {price_label}{" "}
        {displayUnit && (
          <>
            / {conditionnement} ({nb_cond_vte} unité)
          </>
        )}
      </Box>
    </Typography>
  );
}

ProductListPrice.selectors = selectors;
