import {
  AddProductsToCartFab,
  ProductListItem,
  ProductListItemRenderer,
} from "@graphcommerce/magento-product";
import { ProductListItemBundle } from "@graphcommerce/magento-product-bundle";
import { ProductListItemConfigurable } from "@graphcommerce/magento-product-configurable";
import { ProductListItemDownloadable } from "@graphcommerce/magento-product-downloadable";
import { ProductListItemVirtual } from "@graphcommerce/magento-product-virtual";
import { ProductReviewSummary } from "@graphcommerce/magento-review";
import { ProductWishlistChip } from "@graphcommerce/magento-wishlist";
import { ProductListItemSimple } from "../ProductCard/ProductListItemSimple";

export const productListRenderer: ProductListItemRenderer = {
  Skeleton: (props) => <ProductListItem {...props} aspectRatio={[1, 1]} />,
  SimpleProduct: (props) => {
    return <ProductListItemSimple {...props} aspectRatio={[1, 1]} />;
  },
  ConfigurableProduct: (props) => (
    <ProductListItemConfigurable
      {...props}
      aspectRatio={[1, 1]}
      swatchLocations={{
        topLeft: [],
        topRight: [],
        bottomLeft: [],
        bottomRight: [],
      }}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  BundleProduct: (props) => (
    <ProductListItemBundle
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  VirtualProduct: (props) => {
    const { sku } = props;
    return (
      <ProductListItemVirtual
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} />}
      />
    );
  },
  DownloadableProduct: (props) => (
    <ProductListItemDownloadable
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
      topRight={<ProductWishlistChip {...props} />}
    />
  ),
  GroupedProduct: (props) => (
    <ProductListItemSimple {...props} aspectRatio={[1, 1]} />
  ),
};
